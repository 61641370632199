<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-02-20 18:35:53
 * @LastEditTime: 2021-03-01 13:26:11
 * @FilePath: \acuconference-web\src\views\FullPageLoading.vue
-->
<template>
  <div class="full-page-loading-wrapper">
    <v-progress-circular
      :size="70"
      width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("userInfo", ["relogin"]),
    async reLoginWhenFullPageLoading() {
      try {
        const result = await this.relogin();
        if (!result) {
          this.$bus.$emit("update_self_info");
        }
        this.$nextTick(() => {
        console.log("will go -1");
          this.$router.go(-1);
        });
      } catch (error) {
        
        this.$login();
        this.$nextTick(() => this.$router.push({
          name: 'JoinMeeting'
        }))
      }
      
    },
  },

  mounted() {
    this.reLoginWhenFullPageLoading();
  },
};
</script>

<style lang="sass" scoped>
.full-page-loading-wrapper
  position: relative
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
</style>